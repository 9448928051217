import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, map, firstValueFrom } from 'rxjs';

import { StoreEntity } from '@loyx/common';
import { StoresService } from '../../../backoffice/stores/stores.service';

@Component({
  selector: 'stores-typeahead',
  templateUrl: './stores-typeahead.component.html',
  styleUrls: ['./stores-typeahead.component.scss'],
})
export class StoresTypeAhead implements OnInit, OnChanges {
  @Input('control') storeIdFormControl: FormControl;

  @ViewChild('storeFilterInput') storeFilterInput: ElementRef;
  @Output() onSelectedStore: EventEmitter<any> = new EventEmitter<any>();

  stores: StoreEntity[] = [];
  filteredStores: StoreEntity[] = [];
  loadingStores = true;

  constructor(private storesService: StoresService) {}

  async ngOnInit(): Promise<void> {
    this.loadingStores = true;

    await this.loadStores();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.stores) this.filteredStores = this.stores;
  }

  async loadStores() {
    const resStores = await firstValueFrom(
      this.storesService.queryAll({
        pagination: {
          perpage: 1000,
          page: 1,
        },
        sorting: {
          order: 'ASC',
          orderby: 'description',
        },
      })
    );
    this.stores = resStores.data;
    this.resetStores();

    if (this.storeIdFormControl) {
      this.storeIdFormControl.valueChanges.subscribe((data) => {
        this.onSelectionChange(data);
      });
    }

    this.loadingStores = false;
  }

  private filter(value: string): any[] {
    const filterValue = value.toLowerCase();
    return this.stores.filter((store) => store.description.toLowerCase().includes(filterValue));
  }

  getFilteredOptions(value: string): Observable<any[]> {
    return of(value).pipe(map((filterString) => this.filter(filterString)));
  }

  onChange($event?: any) {
    if (this.storeIdFormControl && $event.data == null) {
      this.storeIdFormControl.setValue(null, {
        emitEvent: false,
      });
      this.storeIdFormControl.markAsTouched();
    }

    this.getFilteredOptions(this.storeFilterInput.nativeElement.value).subscribe((filteredStores) => {
      this.filteredStores = filteredStores;
    });
  }

  onSelectionChange(newValue: any) {
    const selectedStore = newValue ? this.filteredStores.find((store) => store._id === newValue) : null;

    this.storeFilterInput.nativeElement.value = selectedStore?.description || '';
    if (this.storeIdFormControl) {
      this.storeIdFormControl.setValue(selectedStore?._id, {
        emitEvent: false,
      });
    }
    this.onSelectedStore.emit(selectedStore);
  }

  setStoreIdStatus() {
    this.storeIdFormControl.markAsTouched();
  }

  clearInput($event) {
    $event.preventDefault();
    if (this.storeIdFormControl) {
      this.storeIdFormControl.setValue(null);
      this.storeIdFormControl.markAsTouched();
    } else {
      if (this.storeFilterInput.nativeElement.value) {
        this.onSelectedStore.emit();
      } else this.storeFilterInput.nativeElement.value = '';
    }

    this.resetStores();
  }

  resetStores() {
    this.filteredStores = this.stores;
    this.storeFilterInput.nativeElement.value = '';
  }
}
