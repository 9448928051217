export enum ESettingsName {
  GENERAL = 'general',
  CARDS = 'cards',
}

export enum EIntegrationCodes {
  SISTORE = "sistore",
  // OTHER = "other",
  // UNKNOW = "unknow",
}

export interface IIntegrationInfo {
  code: EIntegrationCodes;
  name: string;
  description: string;
}

export const IntegrationsList: IIntegrationInfo[] = [{
  code: EIntegrationCodes.SISTORE,
  name: "Gestionale Si Store",
  description: "Importa automaticamente le tessere e le transazioni di acquisto di tutti i punti vendita del gestionale Si Store by SIRETAIL.",
}];