import { EUserRole, EUserType } from '../constants';
import { DbResourceEntity } from './db.entity';
import { StoreEntity } from './store.entity';
import { StoreGroupEntity } from './store-group.entity';
import { OrganizationEntity } from './organization.entity';

export class UserEntity extends DbResourceEntity {
  active: boolean;
  type: EUserType | string;
  role: EUserRole | string;
  firstName: string;
  lastName?: string;
  username: string;
  email: string;
  // @Exclude()
  password?: string;
  organizationId?: string;
  storeGroupId?: string;
  storeId?: string;
  otpPasswordReset?: string;
  otpPasswordResetExpire?: string;

  readonly organization?: OrganizationEntity;
  readonly storeGroup?: StoreGroupEntity;
  readonly store?: StoreEntity;

  constructor(data?: Omit<UserEntity, keyof DbResourceEntity>) {
    super();

    if (data) Object.assign(this, data);
  }
}

export class AdminUserEntity extends UserEntity {
  override type: EUserType.ADMIN_USER;
}

export class OrganizationUserEntity extends UserEntity {
  constructor(organizationId: string, entityData?: Omit<OrganizationUserEntity, 'organization'>) {
    super();

    this.organizationId = organizationId;

    if (entityData) Object.assign(this, entityData);
  }

  override type: EUserType.ORGANIZATION_USER;
}

// export class UserJwtTokenPayload {
//   // _id: string;
//   userid: string;
//   type: string;
//   role: string;
//   username: string;
//   email: string;
//   organization?: string;
// }
