import { Injectable, NgZone } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Subscription, interval } from "rxjs";

@Injectable({ providedIn: 'root' })
export class AppUpdateService {
    isNewVersionAvailable: boolean = false;
    checkInterval$ = interval(15 * 60 * 1000);
    checkIntervalSubscription: Subscription;

    constructor(
        private swUpdate: SwUpdate,
        private zone: NgZone
    ) {
        this.checkAndObserveUpdates();
    }

    async checkAndObserveUpdates() {
        this.checkIntervalSubscription?.unsubscribe();

        if (!this.swUpdate.isEnabled) {
            return;
        }

        this.isNewVersionAvailable = await this.updateIsAvailable();

        this.zone.runOutsideAngular(() => {
            this.checkIntervalSubscription = this.checkInterval$.subscribe(async () => {
                this.isNewVersionAvailable = await this.updateIsAvailable();

                if (this.isNewVersionAvailable)
                    this.checkIntervalSubscription?.unsubscribe();
            });
        })
        //     this.swUpdate.versionUpdates.subscribe(evt => {
        //         console.log({ evt });
        //         switch (evt.type) {
        //             case 'VERSION_DETECTED':
        //                 console.log(`Downloading new app version: ${evt.version.hash}`);
        //                 break;
        //             case 'VERSION_READY':
        //                 console.log(`Current app version: ${evt.currentVersion.hash}`);
        //                 console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
        //                 break;
        //             case 'VERSION_INSTALLATION_FAILED':
        //                 console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
        //                 break;
        //         }
        //     });
    }

    private async updateIsAvailable() {
        let updateIsAvailable = false;

        try {
            updateIsAvailable = await this.swUpdate.checkForUpdate();
            console.log(updateIsAvailable ? 'A new version is available.' : 'Already on the latest version.');
        } catch (error) {
            console.error('Failed to check for updates:', error);
        }

        return updateIsAvailable;
    }


    applyUpdate(): void {
        this.swUpdate.activateUpdate()
            .then(() => document.location.reload())
            .catch(error => console.error('Failed to apply updates:', error));
    }
}