import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { of, fromEvent, firstValueFrom } from 'rxjs';
import { map, switchMap, distinctUntilChanged } from 'rxjs/operators';

import { AccountEntity } from '@loyx/common';
import { AccountsService } from '../../../backoffice/accounts/accounts.service';

@Component({
  selector: 'accounts-typeahead',
  templateUrl: './accounts-typeahead.component.html',
  styleUrls: ['./accounts-typeahead.component.scss'],
})
export class AccountsTypeAhead implements OnInit, OnChanges, AfterViewInit {
  @Input('control') accountIdFormControl: FormControl;

  @ViewChild('accountFilterInput') accountFilterInput: ElementRef;
  @Output() onSelectedAccount: EventEmitter<any> = new EventEmitter<any>();

  accounts: AccountEntity[] = [];
  filteredAccounts: AccountEntity[] = [];
  loadingAccounts = false;
  timeoutId;

  constructor(private accountsService: AccountsService) {}

  ngOnInit(): void {
    if (this.accountIdFormControl) {
      this.accountIdFormControl.valueChanges.subscribe((data) => {
        this.onSelectionChange(data);
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.accounts) this.filteredAccounts = this.accounts;
  }

  ngAfterViewInit(): void {
    this.setupSearch();
  }

  setupSearch(): void {
    fromEvent(this.accountFilterInput.nativeElement, 'input')
      .pipe(
        map((event: any) => event.target.value),
        distinctUntilChanged(),
        switchMap((value) => {
          if (value.length >= 3) {
            this.loadingAccounts = true;
            return this.loadAccounts(value);
          } else {
            return of([]);
          }
        })
      )
      .subscribe((accounts) => {
        this.filteredAccounts = accounts;
        this.loadingAccounts = false;
        setTimeout(() => {
          this.accountFilterInput.nativeElement.dispatchEvent(
            new Event('input', { bubbles: true, cancelable: true })
          );
        }, 0);
      });
  }

  loadAccounts(query: string): Promise<AccountEntity[]> {
    clearTimeout(this.timeoutId);

    return new Promise((res) => {
      this.timeoutId = setTimeout(async () => {
        const results = (await this.getAccounts(query))?.data;

        res(results);
      }, 1000);

      return;
    });
  }

  private getAccounts(query: string) {
    return firstValueFrom(
      this.accountsService.queryAll({
        pagination: {
          perpage: 1000,
          page: 1,
        },
        sorting: {
          order: 'ASC',
          orderby: 'description',
        },
        filters: {
          description: query,
        },
      })
    );
  }

  onChange($event?: any) {
    if (this.accountIdFormControl && !$event?.target?.value) {
      this.accountIdFormControl.setValue(null, {
        emitEvent: false,
      });
      this.accountIdFormControl.markAsTouched();
    }
  }

  onSelectionChange(newValue: any) {
    const selectedAccount = newValue
      ? this.filteredAccounts.find((account) => account._id === newValue)
      : null;

    this.accountFilterInput.nativeElement.value = selectedAccount?.email || '';
    if (this.accountIdFormControl) {
      this.accountIdFormControl.setValue(selectedAccount?._id, {
        emitEvent: false,
      });
    }
    this.onSelectedAccount.emit(selectedAccount);
  }

  setAccountIdStatus() {
    this.accountIdFormControl.markAsTouched();
  }

  clearInput($event) {
    $event.preventDefault();
    if (this.accountIdFormControl) {
      this.accountIdFormControl.setValue(null);
      this.accountIdFormControl.markAsTouched();
    } else {
      if (this.accountFilterInput.nativeElement.value) {
        this.onSelectedAccount.emit();
      } else this.accountFilterInput.nativeElement.value = '';
    }

    this.resetAccounts();
  }

  resetAccounts() {
    this.filteredAccounts = [];
    this.accountFilterInput.nativeElement.value = '';
  }
}
