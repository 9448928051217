<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center" *ngIf="selectedTab === 'Upload'">
      {{ multiFiles ? 'Carica uno o più media' : "Carica un media" }}
    </div>
    <div class="d-flex align-items-center" *ngIf="selectedTab === 'Galleria'">
      {{ multiSelect ? 'Seleziona uno o più media' : "Seleziona un media" }}
    </div>

    <div class="closeDialog">
      <button size="tiny" nbButton ghost>
        <nb-icon [icon]="'close-outline'" pack="eva" (click)="closeDialog()"> </nb-icon>
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <nb-tabset fullWidth (changeTab)="selectTab($event)">
      <nb-tab tabTitle="Galleria"></nb-tab>
      <nb-tab tabTitle="Upload"></nb-tab>
    </nb-tabset>
    <media-upload *ngIf="selectedTab === 'Upload'" [multiUpload]="multiFiles"
      (uploadedImageOutput)="handleUploadedImage($event)"></media-upload>

    <media-gallery *ngIf="selectedTab === 'Galleria'" [imageFromParent]="selectedImage" [multiSelection]="multiSelect"
      (selectedImageOutput)="handleSelectedImage($event)"></media-gallery>
  </nb-card-body>
  <nb-card-footer class="d-flex footer column justify-content-between align-items-center"
    [class.flex-column]="isScreenSmall()" [class.flex-row]="!isScreenSmall()">

    <!-- <div class="fileSelected d-flex mx-4" *ngIf="selectedImage && !isArray(selectedImage)">
      <media-preview [media]="selectedImage" [width]="100" [height]="100"> </media-preview>
      <div class="mt-3 ml-3 fileSelectedInfo" [class.text-center]="isScreenSmall()">
        <p class="fileName"><strong>Nome file:</strong> {{ selectedImage?.name }}</p>
        <p class="fileName"><strong>Dimensione:</strong> {{ selectedImage?.size | bytes }}</p>
      </div>
    </div> -->

    <div class="filesSelected row">
      <div class="d-flex mx-3" style="gap: 0.3rem;" *ngIf="selectedImage">
        <ng-container *ngIf="!isArray(selectedImage)">
          <media-preview [media]="selectedImage" [width]="70" [height]="70"> </media-preview>
        </ng-container>

        <ng-container *ngIf="isArray(selectedImage)">
          <media-preview *ngFor="let image of selectedImage" [media]="image" [width]="70" [height]="70">
          </media-preview>
        </ng-container>
      </div>
    </div>

    <button class="cancel" nbButton [disabled]="!selectedImage || typeNotAllowed" status="success" (click)="confirm()">
      Seleziona
    </button>
  </nb-card-footer>
</nb-card>