import { IMongodbDocument } from './mongodb';

import { IOrganizationEntityDocument } from './organizations';
import { IStore } from './stores';
import { ECardStatus, ECustomerGender } from '../constants';
import { IAccount } from './accounts';
import { IAgreements, IConsentEntity } from './agreements';
import { IUser } from './users';
import { IApiToken } from './api-tokens';

export interface ICardType extends IMongodbDocument, IOrganizationEntityDocument {
  /** Codice univoco tipo carta */
  code: string;
  /** Descrizione tipo carta */
  description: string;
  /** Tipo carta predefinito per l'organizzazione */
  default?: boolean;
  /** Regolamento */
  termsAgreementId?: string;
  termsAgreement?: IAgreements;
}

export interface ICard extends IMongodbDocument, IOrganizationEntityDocument {
  /** Tipo carta */
  cardTypeId: string;
  cardType?: ICardType; // PopulatedDoc<ICardType>;// MongoIdOrDocument<ICardType>; PopulatedDoc
  /** Carta attiva/non attiva */
  active?: boolean;
  /** Codice carta (univoco per organizzazione) */
  code: number;
  /**  */
  barcode?: string;
  /** Data di attivazione della carta */
  activationDate?: string;
  /** Negozio predefinito carta */
  storeId?: string;
  store?: IStore;
  /**
   * CLIENTE o AZIENDA
   */
  /** Cliente business */
  businessCustomer?: boolean;
  /** Nome cliente */
  firstName?: string;
  /** Cognome cliente */
  lastName?: string;
  /** */
  gender?: ECustomerGender;
  /** */
  birthDate?: string;
  /** Ragione Sociale */
  companyName?: string;
  /** Partita iva */
  companyVatNumber?: string;
  /** Nota private */
  privateNote?: string;
  /**
   * CONTATTI
   */
  /** Email */
  email?: string;
  /** Telefono */
  phone?: string;
  /** Indirizzo */
  address?: {
    // street?: string;
    address1?: string;
    address2?: string;
    province?: string;
    city?: string;
    postal?: string;
    country?: string;
  };
  /** Utente che ha creato la card */
  createdByUserId?: string;
  createdByUser?: IUser;

  createdByApiTokenId?: string;
  createdByApiToken?: IApiToken;

  /**
   *
   *
   * CONSENSI
   */
  consents?: IConsentEntity[];
  /**
   * ALTRO
   */
  /** Account associato alla card */
  accountId?: string;
  account?: IAccount;
  /** Data associazione ad account */
  dataAssociation?: string;
  /** Stato carta  */
  status?: ECardStatus;
  /**
   * STATISTICHE
   */
  /** Numero degli acquisti effettuati dalla carta */
  purchasesCount?: number;
  /** Importo totale degli acquisti effettuati dalla carta */
  purchasesAmount?: number;
  /** Importo medio sul totale acquisti dalla carta */
  purchasesAverageAmount?: number;
  /** Data del primo acquisto effettuato dalla carta */
  firstPurchaseDate?: string;
  /** Data dell'ultimo acquisto effettuato dalla carta */
  lastPurchaseDate?: string;

  /** Carta disabilitata/non disabilitata */
  disabled?: boolean;
  /** Data disabilitazione */
  disablingDate?: string;
}

/**
 *
 */
export type TCard = Omit<Partial<ICard>, 'type' | 'store'> & {
  type: string;
  store?: string;
};
