import { IMongodbDocument } from './mongodb';

import { IOrganizationEntityDocument } from './organizations';

export interface IStore extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  code: number;
  default: boolean;
  description: string;
  storeGroupId?: string;
  storeGroup?: IStoreGroup;
  address?: string;
  city?: string;
  lat?: string;
  long?: string;
  email?: string;
  website?: string;
  contact?: string;
}

export interface IStoreGroup extends IMongodbDocument, IOrganizationEntityDocument {
  active: boolean;
  description: string;
}
