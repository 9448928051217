import { DbResourceEntity } from './db.entity';
import { EBarcodeType, IIntegrationInfo } from '../constants';
import { CampaignEntity } from './campaign.entity';

export class OrganizationEntity extends DbResourceEntity {
  constructor(entityData?: Omit<OrganizationEntity, keyof DbResourceEntity>) {
    super();

    if (entityData) Object.assign(this, entityData);
  }

  /** */
  description: string;
  /** */
  settings?: SettingsEntity;
  /** */
  integrations?: IntegrationsEntity;
}

export class SettingsEntity {
  general?: SettingsGeneralEntity;
  cards?: SettingsCardsEntity;
  // privacy?: SettingsPrivacyEntity;
}

export class SettingsGeneralEntity {
  /** Notifiche email */
  notifications?: boolean;
}

export class SettingsCardsEntity {
  /** Circolarità attiva sull'organizzazione. Se non attiva le tessere sono valide sono nel negozio o gruppo negozi di appartenza */
  circularity?: boolean;
  /** Generazione barcode automatica */
  barcodeGeneration?: boolean;
  /** Tipologia del barcode */
  barcodeType?: EBarcodeType;
  /** Prefisso barcode */
  barcodePrefix?: number;

  // /** Tipo di generazione del codice */
  // codeGeneration?: ECardCodeGenerationType;
  // /** Tipologia del codice carte */
  // codeType?: ECardCodeType;
  // // codeGenerationRules: {}
}

// export class SettingsPrivacyEntity {
//   policies: {
//     version: number;
//     type: string; // text/editor - url
//     text?: string;
//     url?: string;
//   }[];
//   agreements: {
//     _id: string;
//     type: string; // tos - marketing - targeted - third_party
//   }[];
// }

export class IntegrationConfigEntity {
  code: string;
  active: boolean;
  info?: IIntegrationInfo;
}
export class SistoreIntegrationConfigEntity extends IntegrationConfigEntity {
  mainCampaign?: CampaignEntity | string;
  validateBarcode?: boolean;
}

export class IntegrationsEntity {
  sistore?: SistoreIntegrationConfigEntity;
}
