import { DbOrganizationResourceEntity } from './db.entity';
import { StoreGroupEntity } from './store-group.entity';

export class StoreEntity extends DbOrganizationResourceEntity<StoreEntity> {
  active: boolean;
  code: number;
  description: string;
  storeGroupId?: string;
  address?: string;
  city?: string;
  lat?: string;
  long?: string;
  email?: string;
  website?: string;
  contact?: string;
  // /** Negozio predefinito nell'organizzazione */
  // default?: boolean;

  readonly storeGroup?: StoreGroupEntity;
}
