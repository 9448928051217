import { Component, EventEmitter, Input, Output } from '@angular/core';

import { IConsentEntity } from '@loyx/common';

@Component({
  selector: 'si-consents-item-list',
  templateUrl: './consents-item-list.component.html',
  styleUrls: ['./consents-item-list.component.scss'],
})
export class ConsentsItemListComponent {
  @Input('consents') consent: IConsentEntity;
  @Output() onRefuse: EventEmitter<IConsentEntity> = new EventEmitter<IConsentEntity>();

  constructor() { }
}
