<nb-list>
  <nb-list-item class="d-flex justify-content-between">
    <div>
      {{ consent.agreementTitle }} -
      <span *ngIf="consent.accepted" class="text-success font-weight-bold"> Accettato</span>
      <span *ngIf="!consent.accepted" class="text-warning font-weight-bold"> Rifiutato</span>
      <span class="mx-1" *ngIf="consent.date"
        >il <span class="font-weight-bold">{{ consent.date | date }}</span></span
      >
      <span *ngIf="consent.source"
        >da <span class="font-weight-bold">{{ consent.source }}</span>
      </span>
    </div>

    <div *ngIf="consent.accepted">
      <button
        nbButton
        size="small"
        status="warning"
        (click)="onRefuse.emit(consent)"
        [hasPermission]="['update', 'org_accounts']"
      >
        Rifiuta
      </button>
    </div>
  </nb-list-item>
</nb-list>
